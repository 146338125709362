// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-product-strapi-products-slug-js": () => import("./../../../src/pages/product/{strapiProducts.slug}.js" /* webpackChunkName: "component---src-pages-product-strapi-products-slug-js" */),
  "component---src-pages-product-strapi-products-slug-tariffs-js": () => import("./../../../src/pages/product/{strapiProducts.slug}/tariffs.js" /* webpackChunkName: "component---src-pages-product-strapi-products-slug-tariffs-js" */),
  "component---src-pages-reviews-js": () => import("./../../../src/pages/reviews.js" /* webpackChunkName: "component---src-pages-reviews-js" */),
  "component---src-pages-school-js": () => import("./../../../src/pages/school.js" /* webpackChunkName: "component---src-pages-school-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-solution-strapi-solutions-slug-js": () => import("./../../../src/pages/solution/{strapiSolutions.slug}.js" /* webpackChunkName: "component---src-pages-solution-strapi-solutions-slug-js" */),
  "component---src-pages-tour-js": () => import("./../../../src/pages/tour.js" /* webpackChunkName: "component---src-pages-tour-js" */),
  "component---src-templates-article-amp-js": () => import("./../../../src/templates/article-amp.js" /* webpackChunkName: "component---src-templates-article-amp-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-content-js": () => import("./../../../src/templates/content.js" /* webpackChunkName: "component---src-templates-content-js" */),
  "component---src-templates-course-js": () => import("./../../../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */)
}

